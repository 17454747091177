import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useEffect, useState } from 'react';
import Axios from 'axios';



function App() {

  const [lst,setLst] = useState([])
  let txt=''

  // useEffect(async() => {
  //   let abc2 = await Axios.get ('https://backend.parkridetech.com')
  //   setLst (abc2.data)
  // },[]);
  // useEffect(() => {
  //   let abc2 = Axios.get ('https://backend.parkridetech.com')
  //   setLst (abc2.data)
    
  // }, []);

return (<>
    <Container>
      <Row>
        <Col sm={8}>
          <h6>React Project</h6>
          <h6>CRUD application</h6>
        </Col>
        <Col sm={4}></Col>
      </Row>
      <Row>
        <Col sm={3}></Col>
        <Col sm={6}>
          <div style={{ backgroundColor: 'skyblue' }}>
            <InputGroup className="mb-3" >
              <Form.Control
                placeholder="Enter Department"
                aria-label="Enter Department"
                aria-describedby="basic-addon2"
                onChange={(obj)=>{
                  txt = obj.target.value
                }}
              />
              <InputGroup.Text id="basic-addon2">@Admin Depart</InputGroup.Text>
              </InputGroup>
              <button className="mb-3" style={{margin:'10px'}}
              onClick={async()=>{
                // await Axios.post ('https://www.parkridetech.com/api/NonQuery' ,{mySQL:"insert into tbl_depart (dpt_Name) values('"+txt+"') "}).then ((res)=>
                // {
                //   // setLst (res.data)
                // }
                // )
                
                // await Axios.get ('https://www.parkridetech.com/api/DataQuery' ,{params: {sql:'Select * from tbl_depart'}}).then ((res)=>
                // {
                //   setLst (res.data)
                // }
                // )
                await Axios.post ('https://backend.parkridetech.com',{sql:"insert into tbl_depart (dpt_Name) values ('"+txt+"') "})
                alert('Successfully Add')
                let abc2 = await Axios.get ('https://backend.parkridetech.com')
                setLst (abc2.data)
              }}
              >Insert New Depart</button>
{/* 
              <button
              onClick={async ()=>{
                let abc = await Axios.get ('https://backend.parkridetech.com')
                setLst (abc.data)

                // alert('abc')
              }
            }
              >check PHP</button>


          <button
              onClick={async ()=>{
                let abc = await Axios.post ('https://backend.parkridetech.com',{sql:"insert into tbl_depart (dpt_Name) values ('"+txt+"') "})
                // setLst (abc.data)
                alert(abc.data)
                // alert('abc')
              }
            }
              >Check PHP Post</button> */}


          </div>
        </Col>
        <Col sm={3}></Col>
      </Row>

      <Row>
        <Col sm={3}></Col>
        <Col sm={6}>
          {
            lst.map ((row)=> 
             <div style={{display:'flex'}}>
             <div style={{flex:'1', border:'1px solid silver',margin:'5px',padding:'10px'}}>{row.dpt_ID}</div>
             <div style={{flex:'9',border:'1px solid silver',margin:'5px',padding:'10px'}}>{row.dpt_Name}</div>
             </div>
            )
          }
        </Col>
        <Col sm={3}></Col>
      </Row>
    </Container>
  </>);
}

export default App;